<template lang="html">
  <div class="Image" v-if="video" ref="container">
    <div
      class="container"
      :class="{ proportion: item.proportion != 0 }"
      :style="padding"
      ref="container"
    >
      <video
        preload="metadata"
        playsinline
        :title="item.image.title"
        ref="video"
        :data-id="item.id"
      >
        <source :src="'/src/' + video.file" />
      </video>

      <div ref="PreviewImage">
        <Preview v-if="poster" :title="poster.title" :item="poster" />
      </div>
      <div class="controls" ref="controls">
        <div class="play show" ref="play" title="Play">
          <div>
            <img src="@/assets/img/icons/Play.png" alt="Play!" />
          </div>
        </div>
        <div class="pause" ref="pause" title="Pause">
          <div>
            <img src="@/assets/img/icons/Pause.png" alt="Play!" />
          </div>
        </div>
        <div class="audio" ref="audio">
          <div
            class="volumePlus"
            ref="volumePlus"
            title="Increase Volume"
          ></div>
          <div
            class="volumeMinus"
            ref="volumeMinus"
            title="Decrease Volume"
          ></div>
        </div>
      </div>
      <div class="progressContainer" ref="progressContainer" @click="skip">
        <div class="progress" ref="progress" title="Jump"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Preview from '@/components/Container/Picture.vue';

export default {
  components: {
    Preview,
  },
  props: {
    item: null,
  },
  data() {
    return {
      loaded: false,
      show: false,
      debug: false,
      videos: false,
      poster: false,
    };
  },
  computed: {
    padding() {
      if (this.item.proportion != 0) {
        if (this.item.proportion == 107) this.item.proportion = 106.2;
        return 'padding-bottom: ' + this.item.proportion + '%;';
      } else return null;
    },
    video() {
      const width = window.innerWidth;
      let output;

      if (this.videos) {
        const byWidth = this.videos.slice(0);
        byWidth.sort(function(a, b) {
          return a.meta_width - b.meta_width;
        });

        output = this.videos[0];

        this.videos.forEach(video => {
          if (width > output.meta_width) output = video;
        });

        return output;
      } else return false;
    },
  },
  methods: {
    fetchVideo() {
      const req = new FormData();
      req.append('id', this.item.image.id);
      axios.post('/api/images/', req).then(res => {
        this.videos = res.data.content.filter(
          item => item.mimetype == 'video/mp4' && !item.deleted,
        );

        this.poster = res.data.content.filter(
          item => item.mimetype.includes('image') && !item.deleted,
        )[0];

        this.$nextTick(() => {
          this.init();
        });
      });
    },
    init() {
      const buttonPlay = this.$refs.play;
      const buttonPause = this.$refs.pause;
      const previewImage = this.$refs.PreviewImage;
      const controls = this.$refs.controls;
      const video = this.$refs.video;
      const container = this.$refs.container;
      const progress = this.$refs.progress;
      const progressContainer = this.$refs.progressContainer;
      const audio = this.$refs.audio;
      const volumePlus = this.$refs.volumePlus;
      const volumeMinus = this.$refs.volumeMinus;

      // INIT AUDIO
      video.volume = 0.5;
      volumeMinus.style.height = video.volume * 100 + 'px';
      volumePlus.style.height = video.volume * 100 + 'px';

      // AUTOPLAY
      if (this.item.autoplay == 1) {
        video.muted = true;
        video.loop = true;
        video.volume = 0;
        volumeMinus.style.height = video.volume * 100 + 'px';
        volumePlus.style.height = 100 + 'px';

        let options = {
          rootMargin: '250px',
        };

        let observer = new IntersectionObserver(this.autoplay, options);
        observer.observe(video);

        // SUSPEND LISTENER
        // video.addEventListener('suspend', () => {
        //   if (window.innerWidth < 400) {
        //     buttonPlay.classList.toggle('show');
        //     buttonPause.classList.toggle('show');
        //     if (previewImage) previewImage.style.display = 'block';
        //     controls.style.opacity = 1;
        //   }
        // });
      }

      // PLAY BUTTON
      buttonPlay.addEventListener('click', e => {
        buttonPlay.classList.toggle('show');
        buttonPause.classList.toggle('show');
        if (previewImage) previewImage.style.display = 'none';
        controls.style.opacity = 0;
        video.play();
      });

      // PAUSE BUTTON
      buttonPause.addEventListener('click', e => {
        buttonPlay.classList.toggle('show');
        buttonPause.classList.toggle('show');
        video.pause();
      });

      // PLAY
      video.addEventListener(
        'timeupdate',
        () => {
          const played = video.currentTime;
          const duration = video.duration.toFixed(1);
          progress.style.width = (played / duration) * 100 + '%';
        },
        false,
      );

      // HOVER: IN
      container.addEventListener('mouseover', () => {
        controls.style.opacity = 1;
        audio.style.opacity = 1;
        if (video.currentTime != 0) progressContainer.style.opacity = 1;
      });

      // HOVER: OUT
      container.addEventListener('mouseout', () => {
        if (!video.paused && !video.ended) {
          controls.style.opacity = 0;
        }
        audio.style.opacity = 0;
        progressContainer.style.opacity = 0;
      });

      // VOLUME: PLUS
      volumePlus.addEventListener('click', () => {
        if (video.volume < 100) video.volume += 0.1;
        volumeMinus.style.height = video.volume * 100 + 'px';
        volumePlus.style.height = 100 - video.volume * 100 + 'px';
      });

      // VOLUME: MINUS
      volumeMinus.addEventListener('click', () => {
        if (video.volume > 0) video.volume -= 0.1;
        volumeMinus.style.height = video.volume * 100 + 'px';
        volumePlus.style.height = 100 - video.volume * 100 + 'px';
      });

      // HIDE CONTROLS ON NO AUDIO
      // video.addEventListener('loadeddata', function() {
      //   if (typeof this.webkitAudioDecodedByteCount !== 'undefined') {
      //     if (!this.webkitAudioDecodedByteCount) audio.style.display = 'none';
      //   } else if (typeof this.mozHasAudio !== 'undefined') {
      //     if (!this.mozHasAudio) audio.style.display = 'none';
      //   }
      // });

      // RESET
      video.addEventListener('ended', () => {
        setTimeout(() => {
          buttonPlay.classList.toggle('show');
          buttonPause.classList.toggle('show');
          controls.style.opacity = 1;
          video.currentTime = 0;
          progress.style.width = 0;
          if (previewImage) previewImage.style.display = 'block';
        }, 5000);
      });
    },
    skip(e) {
      const progress = this.$refs.progress;
      // progress.classList.add('notransition');

      const video = this.$refs.video;
      const videoWidth = this.$refs.container.offsetWidth;
      var rect = e.target.getBoundingClientRect();
      const skipto = ((e.clientX - rect.left) / videoWidth) * video.duration;
      video.currentTime = skipto;

      // progress.classList.remove('notransition');
    },
    autoplay(e) {
      if (e[0].intersectionRatio > 0) {
        const video = this.$refs.video;
        const buttonPlay = this.$refs.play;
        const buttonPause = this.$refs.pause;
        const previewImage = this.$refs.PreviewImage;
        const controls = this.$refs.controls;

        if (video.paused) {
          buttonPlay.classList.toggle('show');
          buttonPause.classList.toggle('show');
          if (previewImage) previewImage.style.display = 'none';
          controls.style.opacity = 0;

          video.autoplay = true;
          video.play();
        }
      } else {
        const video = this.$refs.video;
        video.pause();
      }
    },
  },
  mounted() {
    if (this.debug)
      console.log('Container: Image Preview created', this.item.image.id);
    this.fetchVideo();
  },
};
</script>

<style lang="css" scoped>
.Image {
  position: relative;
  user-select: none;
  width: 100%;
}

.Image video {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  outline: none;
}

.Image .container {
  position: relative;
}

.Image .proportion video {
  position: absolute;
  top: 0;
}

.Image .Image {
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.Image img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Image .controls {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
}

.Image .controls .play,
.Image .controls .pause {
  position: absolute;
  display: none;
  place-items: center;
  background: white;
  width: 70px;
  height: 70px;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  cursor: pointer;
}

.Image .controls .play img {
  margin-left: 2px;
}

.Image .controls .audio {
  position: absolute;
  display: grid;
  grid-template-rows: min-content min-content;
  height: 100px;
  width: 10px;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 1s;
}

.Image .controls .audio .volumePlus {
  width: 100%;
  background: white;
}

.Image .controls .audio .volumeMinus {
  width: 100%;
  background: #222929;
}

.Image .controls .pause {
  display: none;
}

.Image .progressContainer {
  position: absolute;
  bottom: -1px;
  height: 10px;
  width: 100%;
  background: white;
  opacity: 0;
  cursor: pointer;
  transition: opacity 1s;
}

.Image .progress {
  background: #222929;
  width: 0;
  height: 10px;
  transition: width 0.2s;
}

.Image:hover .progress {
  opacity: 1;
}

.Image .controls .show {
  display: grid;
}
</style>
