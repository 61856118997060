<template lang="html">
  <div class="Container Grid" :class="container.style" ref="block">
    <figure
      v-if="containerImages"
      v-for="image in containerImages"
      :class="{ loading: observe }"
    >
      <Video :item="image" v-if="image.image.category == 'Video'" />
      <Picture :item="image" v-else />
      <Caption :content="image.image" />
    </figure>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import Picture from '@/components/Container/Picture.vue';
import Video from '@/components/Container/Video.vue';
import Caption from '@/components/Container/Caption.vue';

export default {
  components: {
    Picture,
    Video,
    Caption,
  },
  data() {
    return {
      containerImages: null,
      debug: false,
    };
  },
  props: {
    container: null,
    images: null,
    is_top: null,
    observe: null,
  },
  computed: {},
  methods: {
    fetchImages() {
      const images = this.images.filter(
        image => image.container_id == this.container.id,
      );

      let result = [];
      const promises = [];
      for (const image of images) {
        const promise = axios
          .post('/api/images/', qs.stringify({ id: image.item_id }))
          .then(res => {
            image.image = res.data.content[0];
            result.push(image);
            if (this.debug)
              console.log('Container: Image fetched', image.item_id);
          })
          .catch(err => {});
        promises.push(promise);
      }

      Promise.all(promises).then(() => {
        result = result.sort((a, b) => a.is_order - b.is_order);
        this.containerImages = result;
      });
    },
    createObserver() {
      const item = this.$refs.block;
      this.observer = new IntersectionObserver(this.trigger, {
        threshold: 0.01,
      });
      this.observer.observe(item);
    },
    trigger(e) {
      if (e[0].isIntersecting) {
        const items = this.$refs.block.querySelectorAll('.loading');
        let timeout = 0;
        items.forEach(item => {
          timeout += 0;
          setTimeout(() => this.show(item), timeout);
        });
      }
    },
    show(item) {
      item.classList.add('loaded');
    },
  },
  created() {
    if (this.debug) console.log('Container: Created', this.container.id);
    this.fetchImages();
  },
  mounted() {
    if (this.observe) {
      this.$nextTick(() => {
        this.createObserver();
      });
    }
  },
  beforeDestroy() {
    if (this.observe) this.observer.disconnect();
  },
};
</script>

<style lang="css">
.loading {
  position: relative;
  top: 100px;
  transition: all 1s;
}

.loaded {
  top: 0;
}

.Container {
  position: relative;
  margin-bottom: 24px;
}

figure {
  padding: 0;
  margin: 0;
}

figcaption {
  margin-top: 12px;
  margin-bottom: 24px;
}

.Spacer {
  position: relative;
  top: -2px;
  display: inline-block;
  font-size: 12px;
  margin-top: 8px;
  line-height: 1px;
  color: black;
}

.Timeline1nocap .CreditsArtist,
.Timeline1nocap .CreditsTitle,
.Timeline1nocap .CreditsLocation,
.Timeline1nocap .CreditsCreation,
.Timeline1nocap .CreditDetails,
.Timeline1nocap .Spacer,
.Timeline2nocap .CreditsArtist,
.Timeline2nocap .CreditsTitle,
.Timeline2nocap .CreditsLocation,
.Timeline2nocap .CreditsCreation,
.Timeline2nocap .CreditDetails,
.Timeline2nocap .Spacer,
.Timeline3nocap .CreditsArtist,
.Timeline3nocap .CreditsTitle,
.Timeline3nocap .CreditsLocation,
.Timeline3nocap .CreditsCreation,
.Timeline3nocap .CreditDetails,
.Timeline3nocap .Spacer,
.Timeline4nocap .CreditsArtist,
.Timeline4nocap .CreditsTitle,
.Timeline4nocap .CreditsLocation,
.Timeline4nocap .CreditsCreation,
.Timeline4nocap .CreditDetails,
.Timeline4nocap .Spacer,
.Timeline5nocap .CreditsArtist,
.Timeline5nocap .CreditsTitle,
.Timeline5nocap .CreditsLocation,
.Timeline5nocap .CreditsCreation,
.Timeline5nocap .CreditDetails,
.Timeline5nocap .Spacer {
  display: none;
}

@media only screen and (min-width: 600px) {
  .List1 figure {
    grid-column-start: 3;
    grid-column-end: 8;
  }

  .List2 figure {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .List2 .Image {
    grid-column-start: 3;
    grid-column-end: 8;
  }

  .List2 figcaption {
    margin-top: 0;
    grid-column-start: 8;
    grid-column-end: 13;
  }

  .List3 figure {
    grid-column-start: 3;
    grid-column-end: 13;
  }

  .List4 figure:nth-child(1) {
    grid-column-start: 3;
    grid-column-end: 8;
  }

  .List4 figure:nth-child(2) {
    position: absolute;
    grid-column-start: 8;
    grid-column-end: 13;
    width: 100%;
  }

  .List5 figure:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 8;
  }

  .List5 figure:nth-child(2) {
    position: absolute;
    grid-column-start: 8;
    grid-column-end: 13;
    width: 100%;
  }

  .Artworks1 figure {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Artworks2bottom,
  .Artworks3bottom,
  .Artworks7bottom,
  .Artworks8,
  .Artworks8bottom {
    margin-bottom: 24px;
  }

  .Artworks2 figure,
  .Artworks7 figure,
  .Artworks2bottom figure,
  .Artworks3bottom figure,
  .Artworks7bottom figure,
  .Artworks8bottom figure {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Artworks7 figure,
  .Artworks7bottom figure,
  .Artworks8bottom figure {
    justify-items: end;
  }

  .Artworks2 .Image {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .Artworks2 figcaption {
    grid-column-start: 7;
    grid-column-end: 13;
    margin: 0;
  }

  .Artworks2bottom .Image {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .Artworks2bottom figcaption {
    grid-column-start: 7;
    grid-column-end: 13;
    align-self: end;
    margin: 0;
  }

  .Artworks3 figure {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Artworks3 .Image {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .Artworks3 figcaption {
    margin: 0;
    grid-column-start: 9;
    grid-column-end: 13;
  }

  .Artworks3bottom .Image {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .Artworks3bottom figcaption {
    grid-column-start: 9;
    grid-column-end: 13;
    align-self: end;
    margin: 0;
  }

  .Artworks4 figure:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .Artworks4 figure:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 13;
  }

  .Artworks5 figure:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .Artworks5 figure:nth-child(2) {
    grid-column-start: 9;
    grid-column-end: 13;
  }

  .Artworks6 figure:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .Artworks6 figure:nth-child(2) {
    grid-column-start: 5;
    grid-column-end: 13;
  }

  .Artworks7 .Image {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-column-end: 13;
  }

  .Artworks7 figcaption {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 7;
    text-align: right;
    margin: 0;
  }

  .Artworks7bottom .Image {
    grid-row-start: 1;
    grid-column-start: 7;
    grid-column-end: 13;
  }

  .Artworks7bottom figcaption {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 7;
    align-self: end;
    text-align: right;
    margin: 0;
  }

  .Artworks8 figure {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
    justify-items: end;
  }

  .Artworks8 .Image {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: 13;
  }

  .Artworks8 figcaption {
    margin: 0;
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 5;
    text-align: right;
  }

  .Artworks8bottom .Image {
    grid-row-start: 1;
    grid-column-start: 5;
    grid-column-end: 13;
  }

  .Artworks8bottom figcaption {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 5;
    align-self: end;
    text-align: right !important;
    margin: 0;
  }

  .Side1 figure,
  .Side2 figure,
  .Side3 figure,
  .Side4 figure {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Timeline1 .Credits,
  .Timeline1nocap .Credits,
  .Timeline2 .Credits,
  .Timeline2nocap .Credits,
  .Timeline3 .Credits,
  .Timeline3nocap .Credits {
    width: 100%;
  }

  .Timeline1 figure,
  .Timeline1nocap figure {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Timeline2 figure,
  .Timeline2nocap figure {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: 11;
  }

  .Timeline3 figure,
  .Timeline3nocap figure {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: 8;
  }

  .Timeline4 figure,
  .Timeline4nocap figure {
    grid-row-start: 1;
    grid-column-start: 3;
    grid-column-end: 9;
  }

  .Timeline4 figure:nth-child(1),
  .Timeline4nocap figure:nth-child(1) {
    grid-column-start: 3;
    grid-column-end: 7;
  }

  .Timeline4 figure:nth-child(2),
  .Timeline4nocap figure:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 11;
  }

  .Timeline5 figure,
  .Timeline5nocap figure {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .Timeline5 figure:nth-child(1),
  .Timeline5nocap figure:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .Timeline5 figure:nth-child(2),
  .Timeline5nocap figure:nth-child(2) {
    grid-column-start: 7;
    grid-column-end: 13;
  }
}

@media only screen and (min-width: 600px) {
  .Side4 {
    width: calc(100vw - 62px);
    margin-left: calc(((100vw - 36px) / 12 * 5 * -1));
  }

  .Side4 figure {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Side4 figcaption {
    position: absolute;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .Side4 {
    width: calc(100vw - 62px);
    margin-left: calc(((100vw - 36px) / 12 * 4 * -1));
  }

  .Side4 figure {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Side4 figcaption {
    position: absolute;
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 5;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 900px) {
  .Side1 figure {
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .Side2 figure {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Side2 .Image {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  .Side2 figcaption {
    margin: 0;
    grid-column-start: 5;
    grid-column-end: 8;
  }

  .Side3 figure {
    position: relative;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 13;
    grid-template-rows: min-content min-content;
  }

  .Side3 .Image {
    position: relative;
    grid-column-start: 1;
    grid-column-end: 9;
  }

  .Side3 figcaption {
    grid-row-start: 2;
    position: absolute;
    margin: 0;
    grid-column-start: 6;
    grid-column-end: 12;
    margin-top: 24px;
  }

  .Side4 {
    width: calc(100vw - 72px - 15px);
    margin-left: calc(((100vw - 60px) / 12 * 4 * -1));
  }

  .Side4 figure {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  .Side4 figcaption {
    position: absolute;
    width: 100%;
    grid-column-start: 9;
    grid-column-end: 12;
    margin-top: 24px;
  }
}
</style>
